
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 30 30"
       :aria-labelledby="title"
       role="presentation"
  >
      <title  v-if="title">{{title}}</title>
      <rect width="100%" height="100%" :fill="backgroundColor"/>
      <g :fill="iconColor">
          <slot />
      </g>
</svg>
