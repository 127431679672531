import ApiRequest from './ApiRequest'

export default class CustomersRequest extends ApiRequest {

  /**
   * Return a single customer
   *
   * @param {int}      customerId
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getCustomer(customerId, queryParams, successCb, errorCb) {
    this.get(`customers/${customerId}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of customers
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getCustomers(queryParams, successCb, errorCb) {
    this.get('customers', queryParams, successCb, errorCb)
  }

  /**
   * Create a customer
   *
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  createCustomer(payload, successCb, errorCb) {
    this.post('customers', payload, successCb, errorCb)
  }

  /**
   * Update a customer
   *
   * @param {int}      customerId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  updateCustomer(customerId, payload, successCb, errorCb) {
    this.put(`customers/${customerId}`, payload, successCb, errorCb)
  }

  /**
   * Delete a customer
   *
   * @param {int}      customerId
   * @param {function} successCb
   * @param {function} errorCb
   */
  deleteCustomer(customerId, successCb, errorCb) {
    this.delete(`customers/${customerId}`, successCb, errorCb)
  }
}
