<template>
  <div
    class="modal fade"
    v-bind:id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="newCustomerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newCustomerModalLabel">New Customer</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form v-bind:id="formId" @submit.prevent="createCustomer" novalidate>
            <h5>Contact Details</h5>
            <hr />

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input v-model="form.name" class="form-control" name="name" type="text" required />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input v-model="form.email" class="form-control" name="email" type="text" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input v-model="form.phone" class="form-control" name="phone" type="text" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="delivery_note">Delivery Note</label>
                  <textarea v-model="form.delivery_note" class="form-control" name="delivery_note"></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="internal_note">Office/Production Note</label>
                  <textarea v-model="form.delivery_note" rows="4" class="form-control" name="internal_note"></textarea>
                </div>
              </div>
            </div>

            <h5>Group Membership</h5>
            <hr />
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="group">Group</label>
                  <select class="form-control" name="group" v-model="form.group" required>
                    <option v-for="cGroup in customerGroups" :key="cGroup.id" :value="cGroup.id">
                      {{ cGroup.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="group">Discount % (not normally used -ve for a discount +ve for a surcharge)</label>
                  <input
                    v-model="form.discount"
                    class="form-control"
                    name="discount"
                    type="number"
                    style="width: 6rem"
                  />
                </div>
              </div>
            </div>

            <h5>Address</h5>
            <hr />

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="company">Company</label>
                  <input v-model="form.company" class="form-control" name="company" type="text" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="line1">Line 1</label>
                  <input v-model="form.line1" class="form-control" name="line1" type="text" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="line2">Line 2</label>
                  <input v-model="form.line2" class="form-control" name="line2" type="text" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="line3">Suburb</label>
                  <input v-model="form.line3" class="form-control" name="line3" type="text" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="state">State</label>
                  <input v-model="form.state" class="form-control" name="state" type="text" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="form-group">
                  <label for="postcode">Postcode</label>
                  <input v-model="form.postcode" class="form-control" name="postcode" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-8">
                <div class="form-group">
                  <label for="country">Country</label>
                  <input v-model="form.country" class="form-control" name="country" type="text" />
                </div>
              </div>
            </div>
          </form>
          <list-errors :errors="errors" />
        </div>
        <div class="modal-footer">
          <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>
          <button v-if="!submitting" v-bind:form="formId" type="submit" class="btn btn-primary">Create</button>
          <button v-else disabled class="btn btn-primary">Creating</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../utility/EventBus'
import CustomersRequest from '../../api/CustomersRequest'
import ListErrors from '../other/ListErrors.vue'
import MixinCustomerGroups from './mixins/CustomerGroups'
import { mapState } from 'vuex'

export default {
  mixins: [MixinCustomerGroups],
  props: {
    redirectOnCreated: {
      type: Boolean,
      required: false,
      default: true,
    },
    modalId: {
      type: String,
      required: false,
      default: 'newCustomerModal',
    },
    createdEventName: {
      type: String,
      required: false,
      default: 'customer-created',
    },
  },
  data() {
    return {
      formId: 'newCustomerForm',
      errors: [],
      submitting: false,
      form: {
        name: null,
        email: null,
        phone: null,
        delivery_note: null,
        internal_note: null,
        company: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        postcode: null,
        country: null,
        group: 1,
        discount: null,
      },
      request: new CustomersRequest(),
    }
  },
  components: {
    ListErrors,
  },
  mounted() {
    this.$store.dispatch('customerGroups/INIT_GROUPS')
  },
  methods: {
    clearErrors() {
      this.errors = []
    },
    createCustomer() {
      const successCb = (resp) => {
        $(`#${this.modalId}`).modal('hide')
        Object.assign(this.$data, this.$options.data())

        if (this.redirectOnCreated) {
          window.location = resp.data.redirectTo
          return
        }

        EventBus.$emit(this.createdEventName, resp.data.customer)
      }
      const errorCb = (resp) => {
        this.submitting = false
        this.errors = CustomersRequest.getResponseErrors(resp)
      }

      this.submitting = true
      this.clearErrors()
      this.request.createCustomer(this.form, successCb, errorCb)
    },
  },
  computed: {
    ...mapState('customerGroups', {
      customerGroups: (state) => state.groups,
    }),
  },
}
</script>
