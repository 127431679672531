import SizeRequest from '/@js/api/SizeRequest'
import Notification from '/@js/utility/Notification'

const sizes = {
  state: {
    sizes: [],
    sizes_loading: false,
  },
  namespaced: true,
  mutations: {
    SET_SIZES: (state, sizes) => {
      state.sizes = sizes
    },
    SIZES_LOADING: (state, loading) => {
      state.sizes_loading = loading
    },
  },
  actions: {
    INIT_SIZES({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.sizes_loading == false && state.sizes.length == 0) {
        commit('SIZES_LOADING', true)
        const successCb = ({ data }) => {
          commit('SIZES_LOADING', false)

          //convert alias field to an array
          let sizes = data.data
          sizes.map((size) => {
            if (size.alias) {
              size.alias = size.alias.split(',').map((alias) => alias.trim().toLowerCase())
            }
            return size
          })

          commit('SET_SIZES', sizes)
        }
        const errorCb = (resp) => {
          commit('SIZES_LOADING', false)
          new Notification(`Error loading sizes.`, { type: 'error' })
        }

        const params = { paginate: false }
        new SizeRequest().getSizes(params, successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
  },
  getters: {},
}

export default sizes
