<div class="materials-search form-control" v-cloak>
  <multiselect
      v-model="selected"
      :options="options"
      track-by="id"
      label="name"
      placeholder="Pick material"
      :show-labels="false">
  </multiselect>
</div>
