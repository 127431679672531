<template>
    <g id="Layer_1" >
        <path d="M28.55,15.526 C28.45,15.926 28.25,16.226 27.95,16.526 C26.25,18.426 24.35,20.026 22.25,21.426 C20.85,22.326 19.35,23.026 17.65,23.426 C15.35,24.026 13.05,23.826 10.85,23.026 C9.05,22.326 7.35,21.326 5.85,20.226 C4.35,19.126 3.05,17.826 1.85,16.526 C1.65,16.326 1.45,16.026 1.45,15.726 C1.45,15.626 1.45,15.426 1.45,15.326 C1.55,15.026 1.65,14.826 1.85,14.526 C2.65,13.626 3.55,12.726 4.55,11.826 C5.95,10.326 7.55,9.226 9.35,8.326 C10.75,7.626 12.15,7.226 13.75,7.026 C13.95,7.026 14.15,6.926 14.45,6.926 C14.75,6.926 15.15,6.926 15.45,6.926 C15.55,6.926 15.55,6.926 15.65,6.926 C16.15,7.026 16.65,7.026 17.15,7.126 C18.95,7.426 20.55,8.126 22.05,9.126 C24.25,10.426 26.15,12.126 27.95,14.026 C28.25,14.326 28.45,14.626 28.55,15.026 C28.55,15.226 28.55,15.326 28.55,15.526 z M14.95,21.426 C15.45,21.426 15.85,21.326 16.35,21.326 C17.65,21.126 18.85,20.626 20.05,20.026 C22.25,18.826 24.15,17.326 25.85,15.526 C25.95,15.326 25.95,15.226 25.85,15.026 C24.85,13.926 23.65,12.926 22.45,12.026 C21.05,11.026 19.65,10.226 18.05,9.626 C16.55,9.126 15.05,9.026 13.45,9.326 C12.15,9.526 10.95,10.026 9.85,10.626 C7.75,11.726 5.85,13.326 4.25,15.026 C4.15,15.226 4.15,15.326 4.25,15.526 C4.55,15.926 4.95,16.326 5.35,16.726 C6.95,18.226 8.65,19.526 10.55,20.426 C11.95,21.026 13.35,21.426 14.95,21.426 z" :fill="iconColor"/>
        <path d="M15,10.7 C17.6,10.7 19.6,12.8 19.6,15.4 C19.6,17.9 17.5,20 14.9,20 C12.4,20 10.3,17.9 10.3,15.3 C10.4,12.8 12.5,10.7 15,10.7 z M16.4,12.6 C15.6,12.6 15,13.2 15,14 C15,14.8 15.6,15.4 16.4,15.4 C17.1,15.4 17.8,14.8 17.8,14 C17.8,13.2 17.1,12.6 16.4,12.6 z" :fill="iconColor"/>
    </g>
</template>

<script>
  export default {
    props: {
      iconColor: {
        type: String,
        default: '#39B885'
      }
    }
  }
</script>