import IconPreview from '/@js/shared/icons/icons/IconPreview.vue'

export default {
  components: {
    'icon-preview': IconPreview,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    log() {
      return this.item.meta.log || []
    },
    uncheckedLogCount() {
      return this.log
        .map((log) => {
          if (!log.hasOwnProperty('checked')) {
            return false
          }

          return log.checked
        })
        .filter((item) => item !== true).length
    },
    hasUncheckedLogs() {
      return this.uncheckedLogCount > 0
    },
  },
  methods: {
    emitClick(event) {
      this.$emit('click', event)
    },
  },
}
