export default {
  methods: {
    groupMembershipClass(alias) {
      if (alias) {
        return CSS.escape(alias.replace(/\ /g, '-')).toLowerCase()
      }
      return ''
    },
    getCustomerGroups() {
      this.$store.dispatch('customerGroups/INIT_GROUPS')
    },
  },
}
