import ApiRequest from './ApiRequest'

export default class CustomerGroupRequest extends ApiRequest {

  /**
   * Return a single Customer Group
   *
   * @param {int}      groupId
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getCustomerGroup(groupId, queryParams, successCb, errorCb) {
    this.get(`customer-groups/${groupId}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of Customer Groups
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getCustomerGroups(queryParams, successCb, errorCb) {
    this.get('customer-groups', queryParams, successCb, errorCb)
  }

  /**
   * Create a Customer Group
   *
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  createCustomerGroup(payload, successCb, errorCb) {
    this.post('customer-groups', payload, successCb, errorCb)
  }

  /**
   * Update a Customer Group
   *
   * @param {int}      groupId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  updateCustomerGroup(groupId, payload, successCb, errorCb) {
    this.put(`customer-groups/${groupId}`, payload, successCb, errorCb)
  }

  /**
   * Delete a Customer Group
   *
   * @param {int}      groupId
   * @param {function} successCb
   * @param {function} errorCb
   */
  deleteCustomerGroup(groupId, successCb, errorCb) {
    this.delete(`customer-groups/${groupId}`, successCb, errorCb)
  }
}
