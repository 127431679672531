import CustomerGroupsRequest from '/@js/api/CustomerGroupRequest'
import Notification from '/@js/utility/Notification'

const customerGroups = {
  state: {
    groups: [],
    groups_loading: false,
  },
  namespaced: true,
  mutations: {
    SET_GROUPS: (state, groups) => {
      state.groups = groups
    },
    GROUPS_LOADING: (state, loading) => {
      state.groups_loading = loading
    },
  },
  actions: {
    INIT_GROUPS({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.groups_loading == false && state.groups.length == 0) {
        commit('GROUPS_LOADING', true)
        const successCb = ({ data }) => {
          commit('GROUPS_LOADING', false)
          commit('SET_GROUPS', data.data)
        }
        const errorCb = (resp) => {
          commit('GROUPS_LOADING', false)
          new Notification(`Error loading groups.`, { type: 'error' })
        }

        const params = { paginate: false }
        new CustomerGroupsRequest().getCustomerGroups(params, successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
  },
  getters: {},
}

export default customerGroups
