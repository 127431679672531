import '@/assets/sass/app/master.scss'

import Vue from 'vue'

import '/@js/shared/components'
import '/@js/shared/plugins'

//Store
import Vuex from 'vuex'
import materials from './store/Materials.store'
import sizes from './store/Sizes.store'
import customerGroups from './store/CustomerGroups.store'
import customerSearch from './store/CustomerSearch.store'
import trackings from './store/Trackings.store'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,
  modules: {
    materials,
    sizes,
    customerGroups,
    customerSearch,
    trackings,
  },
})

import { EventBus } from './utility/EventBus'
Vue.config.debug = true;
Vue.config.devtools = true;
const app = new Vue({
  store,
  components: {
    // Auth
    LoginForm: () => import('./components/auth/LoginForm.vue'),

    // Dashboard
    OrdersBarChart: () => import('./components/other/OrdersBarChart.vue'),

    // Users
    UsersTable: () => import('./components/users/UsersTable.vue'),
    NewUserModal: () => import('./components/users/NewUserModal.vue'),
    EditUserModal: () => import('./components/users/EditUserModal.vue'),
    ChangePasswordModal: () => import('./components/users/ChangePasswordModal.vue'),

    // Materials
    MaterialsSnazzyTable: () => import('./components/materials/MaterialsSnazzyTable.vue'),
    MaterialsDataTable: () => import('./components/materials/MaterialsDataTable.vue'),
    MaterialsTable: () => import('./components/materials/MaterialsTable.vue'),
    LatestMaterials: () => import('./components/materials/LatestMaterials.vue'),
    NewMaterialModal: () => import('./components/materials/NewMaterialModal.vue'),
    MaterialBtnGroup: () => import('./components/materials/MaterialBtnGroup.vue'),
    EditMaterialModal: () => import('./components/materials/EditMaterialModal.vue'),
    MaterialsSalesTable: () => import('./components/materials/MaterialsSalesTable.vue'),
    MaterialCreate: () => import('./components/materials/MaterialCreate.vue'),
    MaterialShow: () => import('./components/materials/MaterialShow.vue'),
    //DeleteMaterialModal: ()=> import('./components/materials/DeleteMaterialModal.vue'),
    // 'delete-material-modal': DeleteMaterialModal,
    //DeleteMaterialModal: Vue.component('delete-material-modal', ()=> import('./components/materials/DeleteMaterialModal')),

    // Customers
    CustomersTable: () => import('./components/customers/CustomersTable.vue'),
    PrintLabelModal: () => import('./components/customers/PrintLabelModal.vue'),
    CustomerBtnGroup: () => import('./components/customers/CustomerBtnGroup.vue'),
    DeleteCustomerModal: () => import('./components/customers/DeleteCustomerModal.vue'),
    RecentCustomers: Vue.component('recent-customers', () => import('./components/customers/RecentCustomers.vue')),

    //customer groups
    CustomerGroupsSnazzyTable: () => import('./components/customerGroups/CustomerGroupsSnazzyTable.vue'),

    // Invoices
    InvoiceShow: () => import('./components/invoices/invoice-show/index.vue'),
    InvoiceCreate: () => import('./components/invoices/invoice-create/index.vue'),
    InvoicesTable: () => import('./components/invoices/InvoicesTable.vue'),
    UnpaidInvoices: Vue.component('unpaid-invoices', () => import('./components/invoices/UnpaidInvoices.vue')),
    InvoiceStateBadge: Vue.component('invoice-state-badge', () =>
      import('./components/invoices/InvoiceStateBadge.vue'),
    ),

    // Price Groups
    PriceGroupsDataTable: () => import('./components/priceGroups/PriceGroupsDataTable.vue'),
    PriceGroupsTable: () => import('./components/priceGroups/PriceGroupsTable.vue'),
    PriceGroupCreate: () => import('./components/priceGroups/PriceGroupCreate.vue'),
    PriceGroupShow: () => import('./components/priceGroups/PriceGroupShow.vue'),
    PriceGroupsSnazzyTable: () => import('./components/priceGroups/PriceGroupsSnazzyTable.vue'),

    //Pricing Group Tier
    PriceGroupTierCreate: () => import('./components/priceGroupTiers/PriceGroupTierCreate.vue'),
    PriceGroupTierShow: () => import('./components/priceGroupTiers/PriceGroupTierShow.vue'),
    PriceGroupTiersTable: () => import('./components/priceGroupTiers/PriceGroupTiersTable.vue'),

    //Pricing
    PricingCoffeeStencils: () => import('./components/pricing/PricingCoffeeStencils.vue'),
    PricingDataTable: () => import('./components/pricing/PricingDataTable.vue'),
    PricingGroupGrid: () => import('./components/priceGroups/PricingGroupGrid.vue'),
    PricingMain: () => import('./components/pricing/PricingMain.vue'),
    PricingFullCutting: () => import('./components/pricing/PricingFullCutting.vue'),
    PricingSimpleShapes: () => import('./components/pricing/PricingSimpleShapes.vue'),
    PricingSimpleStencil: () => import('./components/pricing/PricingSimpleStencil.vue'),
    PricingSingleItemCutting: () => import('./components/pricing/PricingSingleItemCutting.vue'),
    PricingStamp: () => import('./components/pricing/PricingStamp.vue'),
    PricingSign: () => import('./components/pricing/PricingSign.vue'),
    PricingCreate: () => import('./components/pricing/PricingCreate.vue'),
    TimeBasedPricing: () => import('./components/pricing/TimeBasedPricing.vue'),

    //Material Sizes
    MaterialSizesSnazzyTable: () => import('./components/materialSizes/MaterialSizesSnazzyTable.vue'),
    MaterialSizesDataTable: () => import('./components/materialSizes/MaterialSizesDataTable.vue'),
    MaterialSizeCreate: () => import('./components/materialSizes/MaterialSizeCreate.vue'),
    MaterialSizeShow: () => import('./components/materialSizes/MaterialSizeShow.vue'),
    MaterialSizesTable: () => import('./components/materialSizes/MaterialSizesTable.vue'),

    //Sizes
    SizesSnazzyTable: () => import('./components/sizes/SizesSnazzyTable.vue'),
    SizesDataTable: () => import('./components/sizes/SizesDataTable.vue'),

    //Quote
    Quote: () => import('./components/quote/index.vue'),
    QuoteNoFiles: () => import('./components/quote-no-files/index.vue'),
    QuoteSimpleShapes: () => import('./components/quote-simple-shapes/index.vue'),
    QuoteStencils: () => import('./components/quote-stencils/index.vue'),

    //Trackings
    TrackingsTable: () => import('./components/trackings/TrackingsTable.vue'),
    TrackingDetail: () => import('./components/trackings/TrackingDetail.vue'),
    TrackingDetailsModal: () => import('./components/trackings/TrackingDetailsModal.vue'),

    //Settings
    Settings: () => import('./components/settings/index.vue'),
  },

  methods: {
    seeCustomer({ id }) {
      window.location.href = `/customers/${id}`
    },
    showEditCustomer(id) {
      EventBus.$emit('edit-customer', id)
    },
    getNewInvoiceUrl(customerId) {
      return `/invoices/new?customer_id=${customerId}`
    },
    printLabel(customerId) {
      EventBus.$emit('print-label', customerId)
    },
  },
}).$mount('#app')
