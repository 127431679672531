import CustomersRequest from '/@js/api/CustomersRequest'
import Notification from '/@js/utility/Notification'

const customerSearch = {
  state: {
    customers: [],
    customers_loading: false,
  },
  namespaced: true,
  mutations: {
    SET_CUSTOMERS: (state, customers) => {
      state.customers = customers
    },
    CUSTOMERS_LOADING: (state, loading) => {
      state.customers_loading = loading
    },
  },
  actions: {
    INIT_CUSTOMERS({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.customers_loading == false && state.customers.length == 0) {
        commit('CUSTOMERS_LOADING', true)
        const successCb = ({ data }) => {
          commit('CUSTOMERS_LOADING', false)
          commit('SET_CUSTOMERS', data.customers)
        }
        const errorCb = (resp) => {
          commit('CUSTOMERS_LOADING', false)
          new Notification(`Error loading customers.`, { type: 'error' })
        }

        const params = { paginate: false }
        new CustomersRequest().getCustomers(params, successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
    SEARCH({ commit, state }, query) {
      commit('CUSTOMERS_LOADING', true)

      const successCb = ({ data }) => {
        commit('CUSTOMERS_LOADING', false)
        commit('SET_CUSTOMERS', data.customers)
      }

      const errorCb = (resp) => {
        commit('CUSTOMERS_LOADING', false)
        new Notification(`Error searching customers.`, { type: 'error' })
      }

      const param = {
        q: query,
        paginate: false,
      }

      new CustomersRequest().getCustomers(param, successCb, errorCb)
    },
  },
  getters: {
    filteredResults: (state) => {
      let filtered = state.customers
      return filtered.map((c) => {
        return {
          id: c.id,
          name: c.name,
          email: c.email,
          group: c.group,
          phone: c.phone,
          internal_note: c.internal_note,
          discount: c.discount,
          address: c.addresses[0],
          lastInvoice: c.lastInvoice,
        }
      })
    },
  },
}

export default customerSearch
