import ApiRequest from './ApiRequest'

export default class MaterialsRequest extends ApiRequest {

  /**
   * Return a single material
   *
   * @param {int}      materialId
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getMaterial(materialId, queryParams, successCb, errorCb) {
    this.get(`materials/${materialId}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of materials
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getMaterials(queryParams, successCb, errorCb) {
    this.get('materials', queryParams, successCb, errorCb)
  }

  /**
   * Create a material
   *
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  createMaterial(payload, successCb, errorCb) {
    this.post('materials', payload, successCb, errorCb)
  }

  /**
   * Update a material
   *
   * @param {int}      materialId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  updateMaterial(materialId, payload, successCb, errorCb) {
    this.put(`materials/${materialId}`, payload, successCb, errorCb)
  }

  /**
   * Delete a material
   *
   * @param {int}      materialId
   * @param {function} successCb
   * @param {function} errorCb
   */
  deleteMaterial(materialId, successCb, errorCb) {
    this.delete(`materials/${materialId}`, successCb, errorCb)
  }

  getSales(successCb, errorCb) {
    this.get('materials/sales', {}, successCb, errorCb)
  }

  search(query, successCb, errorCb) {
    this.get('materials/search', { query }, successCb, errorCb)
  }
}
