import multiselect from 'vue-multiselect';

export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            required: true
        }
    },
    components: {
        multiselect
    },
    computed: {
        selected: {
            get () {
                return this.options.find(option => option.id === this.material)
            },
            set (id) {
                this.material = id
            }
        },
        material: {
            get () {
                return this.value
            },
            set (value) {
                if (value === null) { // Selecting the same value defaults to null
                    return
                }

                this.$emit('input', value.id)
            }
        }
    }
};
