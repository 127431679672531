import jQuery from 'jquery'
import 'jquery-migrate'
import 'bootstrap'
import Popper from 'popper.js'

import http from '/@js/shared/http'
window.axios = http.create()

window.$ = window.jQuery = jQuery
window.Popper = Popper
jQuery.migrateMute = true

import Vue from 'vue'

import tabs from 'bootstrap-vue'
Vue.use(tabs)

import bFormCheckbox from 'bootstrap-vue'
Vue.use(bFormCheckbox)

import bTooltip from 'bootstrap-vue'
Vue.use(bTooltip)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import VueShortkey from 'vue-shortkey'
Vue.use(VueShortkey)
