import ApiRequest from './ApiRequest'

export default class SizeRequest extends ApiRequest {

  /**
   * Return a single price group
   *
   * @param {int}      sizeSizeId
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getSize(sizeSizeId, queryParams, successCb, errorCb) {
    this.get(`sizes/${sizeSizeId}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of Sizes
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getSizes(queryParams, successCb, errorCb) {
    this.get('sizes', queryParams, successCb, errorCb)
  }

  /**
   * Create a Size
   *
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  createSize(payload, successCb, errorCb) {
    this.post('sizes', payload, successCb, errorCb)
  }

  /**
   * Update a Size
   *
   * @param {int}      sizeId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  updateSize(sizeId, payload, successCb, errorCb) {
    this.put(`sizes/${sizeId}`, payload, successCb, errorCb)
  }

  /**
   * Delete a Size
   *
   * @param {int}      sizeId
   * @param {function} successCb
   * @param {function} errorCb
   */
  deleteMaterialSize(sizeId, successCb, errorCb) {
    this.delete(`sizes/${sizeId}`, successCb, errorCb)
  }
}
