import MaterialsRequest from '/@js/api/MaterialsRequest'
import Notification from '/@js/utility/Notification'

const materials = {
  state: {
    materials: [],
    materials_loading: false,
  },
  namespaced: true,
  mutations: {
    SET_MATERIALS: (state, materials) => {
      state.materials = materials
    },
    MATERIALS_LOADING: (state, loading) => {
      state.materials_loading = loading
    },
  },
  actions: {
    INIT_MATERIALS({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.materials_loading == false && state.materials.length == 0) {
        commit('MATERIALS_LOADING', true)
        const successCb = ({ data }) => {
          commit('MATERIALS_LOADING', false)

          //convert alias field to an array
          let materials = data.data
          materials.map((material) => {
            if (material.alias) {
              material.alias = material.alias.split(',').map((alias) => alias.trim().toLowerCase())
            }
            return material
          })

          commit('SET_MATERIALS', materials)
        }
        const errorCb = (resp) => {
          commit('MATERIALS_LOADING', false)
          new Notification(`Error loading materials.`, { type: 'error' })
        }

        const params = { paginate: false }
        new MaterialsRequest().getMaterials(params, successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
  },
  getters: {},
}

export default materials
