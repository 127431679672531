<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         viewBox="0 0 30 30"
         :aria-labelledby="title"
         role="presentation"
    >
        <title  v-if="title">{{title}}</title>
        <rect width="100%" height="100%" :fill="backgroundColor"/>
        <g :fill="iconColor">
            <slot />
        </g>
  </svg>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: null
      },
      width: {
        type: [Number, String],
        default: 18
      },
      height: {
        type: [Number, String],
        default: 18
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      },
      backgroundColor: {
        type: String,
        default: 'none'
      }
    }
  }
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px;
    }
</style>