<template>
  <div v-show="errors.length" class="alert alert-danger">
    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
  </div>
</template>

<script>
export default {
  props: ['errors'],
}
</script>
