import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default class Notification {
  constructor(message, options) {
    this.options = Object.assign({}, this._getDefaultOptions(), options)

    const notyf = new Notyf({
      duration: this.options.duration,
    })

    switch (this.options.type) {
      case 'success':
        notyf.success(message)
        break
      case 'error':
        notyf.error(message)
        break
      default:
        throw new Error(`'${this.options.type}' is not a valid notification type`)
    }
  }

  /**
   * @return {object}
   */
  _getDefaultOptions() {
    return {
      type: 'success',
      duration: 4000, // 4 secs
    }
  }
}
