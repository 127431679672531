import ApiRequest from './ApiRequest'

export default class UsersRequest extends ApiRequest {

  /**
   * Return a single user
   *
   * @param {int}      userId
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getUser(userId, queryParams, successCb, errorCb) {
    this.get(`users/${userId}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of users
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getUsers(queryParams, successCb, errorCb) {
    this.get('users', queryParams, successCb, errorCb)
  }

  /**
   * Create a user
   *
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  createUser(payload, successCb, errorCb) {
    this.post('users', payload, successCb, errorCb)
  }

  /**
   * Update a user
   *
   * @param {int}      userId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  updateUser(userId, payload, successCb, errorCb) {
    this.put(`users/${userId}`, payload, successCb, errorCb)
  }

  /**
   * Delete a user
   *
   * @param {int}      userId
   * @param {function} successCb
   * @param {function} errorCb
   */
  deleteUser(userId, successCb, errorCb) {
    this.delete(`users/${userId}`, successCb, errorCb)
  }

  /**
   * Restore a user
   *
   * @param {int}      userId
   * @param {function} successCb
   * @param {function} errorCb
   */
  restoreUser(userId, successCb, errorCb) {
    this.put(`users/${userId}/restore`, null, successCb, errorCb)
  }

  /**
   * Change a password
   *
   * @param {int}      userId
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  changeUserPassword(userId, payload, successCb, errorCb) {
    this.put(`users/${userId}/password`, payload, successCb, errorCb)
  }
}
