
<multiselect
        ref="dropdown"
        v-model="value_"
        :loading="loading"
        :options="users"
        :close-on-select="closeOnSelect"

        @select="onSelect"
        @remove="onRemove"
        @search-change="onSearchChange"

        track-by="id"
        :max-height="300"
        label="name"
        :custom-label="name"
        :show-labels="false"
        open-direction="bottom"
        placeholder="Search users"
>

    <template slot="singleLabel" slot-scope="{ option }">
        <div v-if="option.name" class="name_left text-sm">
            <div class="inner_div" :style="gravatarUrl(option)"></div>
        </div>
        <div class="name_right"> {{ option.name }}</div>
    </template>

    <!-- Option Slot - what get shows on the drop down-->
    <template slot="option" slot-scope="props">
        <slot name="option" :option="props.option">
            <div class="name_left">
                <div class="inner_div" :style="gravatarUrl(props.option)"></div>
            </div>
            <div class="name_right"> {{ props.option.name }}</div>
        </slot>
    </template>

</multiselect>
