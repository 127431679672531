import TrackingsRequest from '/@js/api/TrackingsRequest'
import Notification from '/@js/utility/Notification'

const trackings = {
  state: {
    carriers: [],
    tracking_states: [],
    carriers_loading: false,
    states_loading: false,
  },
  namespaced: true,
  mutations: {
    SET_CARRIERS: (state, carriers) => {
      state.carriers = carriers
    },
    CARRIERS_LOADING: (state, loading) => {
      state.carriers_loading = loading
    },
    SET_STATES: (state, states) => {
      state.tracking_states = states
    },
    STATES_LOADING: (state, loading) => {
      state.states_loading = loading
    },
  },
  actions: {
    INIT_CARRIERS({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.carriers_loading == false && state.carriers.length == 0) {
        commit('CARRIERS_LOADING', true)
        const successCb = ({ data }) => {
          commit('CARRIERS_LOADING', false)
          commit('SET_CARRIERS', data.data)
        }
        const errorCb = (resp) => {
          commit('CARRIERS_LOADING', false)
          new Notification(`Error loading Carriers.`, { type: 'error' })
        }
        new TrackingsRequest().getCarriers(successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
    INIT_TRACKING_STATES({ commit, state }) {
      //Only init if we're not already waiting for the data to come down and it's empty
      if (state.states_loading == false && state.tracking_states.length == 0) {
        commit('STATES_LOADING', true)
        const successCb = ({ data }) => {
          commit('STATES_LOADING', false)
          commit('SET_STATES', data.data)
        }
        const errorCb = (resp) => {
          commit('STATES_LOADING', false)
          new Notification(`Error loading Tracking States.`, { type: 'error' })
        }

        new TrackingsRequest().getStates(successCb, errorCb)
      } else {
        // console.log('Extra init requested')
      }
    },
  },
  getters: {},
}

export default trackings
