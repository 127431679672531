import ApiRequest from './ApiRequest'

export default class TrackingsRequest extends ApiRequest {

  /**
   * Return a single invoice
   *
   * @param {string}      trackingNumber
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getTracking(trackingNumber, queryParams, successCb, errorCb) {
    this.get(`trackings/${trackingNumber}`, queryParams, successCb, errorCb)
  }

  /**
   * Return a list of trackings
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getTrackings(queryParams, successCb, errorCb) {
    this.get('trackings', queryParams, successCb, errorCb)
  }

  /**
   * Return a list of carrier
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getCarriers(successCb, errorCb) {
    this.get('trackings/carriers', {}, successCb, errorCb)
  }

  /**
   * Return a list of carrier
   *
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  getStates(successCb, errorCb) {
    this.get('trackings/states', {}, successCb, errorCb)
  }

}
