<div class="view">
  <template v-if="hasUncheckedLogs">
    <span class="notifier badge badge-danger">{{ uncheckedLogCount }}</span>
  </template>
  <a
    href="#"
    @click.prevent="emitClick"
    class="btn-sm btn">
    <icon-base backgroundColor="#39B885" ><icon-preview iconColor="white"/></icon-base>
  </a>
</div>
