<!--
  This is a generic customer search component
  It's just a multiselect with common configs
  when searching for a customer.

  Some events and options should still be
  provided
-->
<template>
  <multiselect
    ref="dropdown"
    v-model="value_"
    :loading="loading"
    :options="customers"
    :close-on-select="closeOnSelect"
    @select="onSelect"
    @remove="onRemove"
    @search-change="onSearchChange"
    track-by="id"
    :max-height="300"
    label="name"
    :custom-label="nameWithEmail"
    :show-labels="false"
    open-direction="bottom"
    placeholder="Search customers"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <div class="text-sm" style="font-size: smaller">
        {{ option.name }} {{ option.email }}
        <span
          v-if="option.group"
          :class="'badge float-right badge-group ' + groupMembershipClass(option.group.alias)"
          >{{ option.group.alias }}</span
        >
      </div>
    </template>

    <!-- Option Slot - shown on the drop down-->
    <template slot="option" slot-scope="{ option }">
      <slot name="option" :option="option">
        <div>
          {{ option.name }}
          <span
            v-if="option.group && option.group.alias !== ''"
            class="badge float-right"
            :class="groupMembershipClass(option.group.alias)"
            >{{ option.group.alias }}</span
          >
        </div>
        <div>{{ `(${option.email})` || '(no email)' }}</div>
      </slot>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash.debounce'
import moment from 'moment'
import MixinCustomerGroups from './mixins/CustomerGroups'
import swal from 'sweetalert'
import { mapGetters } from 'vuex'

export default {
  mixins: [MixinCustomerGroups],
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: false,
    },
    showWarnings: {
      default: false,
    },
    closeOnSelect: {
      required: false,
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  created() {
    // Trigger search without query
    // which will fetch initial dropdown
    // options (first 50 customers)
    this.$store.dispatch('customerSearch/INIT_CUSTOMERS')
  },

  watch: {
    customers() {
      this.$nextTick(() => {
        if (this.$el.querySelector('.dropdown-toggle')) {
          $('.dropdown-toggle').dropdown()

          // Multi-select will close upon losing focus
          // re-open it right away
          $(this.$el).on('show.bs.dropdown', '.btn-group', () => {
            this.$nextTick(() => {
              this.$refs.dropdown.activate()
            })
          })
        }
      })
    },
  },

  methods: {
    nameWithEmail({ id, name, email, group }) {
      return typeof id === 'undefined'
        ? null
        : name + (email == null ? '' : ` (${email})`) + (group === 2 ? ' (VIP)' : ``)
    },

    onSelect(value) {
      if (this.showWarnings) {
        let message = []
        if (
          value.address &&
          value.address.country !== null &&
          value.address.country !== '' &&
          value.address.country.toUpperCase() !== 'AUSTRALIA'
        ) {
          message.push(`GST: The customer address is in ${value.address.country}
           So if we are shipping Overseas then don't charge GST`)
        }

        //6 months - if last invoice date is greater than 6 months ago = alert user
        if (value.lastInvoice) {
          if (
            moment()
              .subtract(6, 'M')
              .isAfter(value.lastInvoice.date)
          ) {
            message.push(
              `ADDRESS: Check their address is current, as last time we posted to them was ${moment(
                value.lastInvoice.date,
              ).fromNow()}`,
            )
          }
          if (moment(value.lastInvoice.date).isBefore('2018-07-01')) {
            message.push(`BANK: They last purchased from our old bank account, please check they have the new details`)
          }
        }
        if (message.length > 0) {
          let messageText = message.join(`\n\n`)
          swal('Oh, Look! ', messageText, {
            button: 'Ok, Got it!',
          })
        }
      }
      this.$emit('select', value)
    },

    onRemove(value) {
      this.$emit('remove', value)
    },

    search(query) {
      this.$store.dispatch('customerSearch/SEARCH', query)
    },
  },

  computed: {
    onSearchChange() {
      return debounce(this.search, 300)
    },

    value_: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    ...mapGetters('customerSearch', {
      customers: 'filteredResults',
    }),
  },
}
</script>

<style>
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962b3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
</style>
