<template>
    <multiselect
            ref="dropdown"
            v-model="value_"
            :loading="loading"
            :options="users"
            :close-on-select="closeOnSelect"

            @select="onSelect"
            @remove="onRemove"
            @search-change="onSearchChange"

            track-by="id"
            :max-height="300"
            label="name"
            :custom-label="name"
            :show-labels="false"
            open-direction="bottom"
            placeholder="Search users"
    >

        <template slot="singleLabel" slot-scope="{ option }">
            <div v-if="option.name" class="name_left text-sm">
                <div class="inner_div" :style="gravatarUrl(option)"></div>
            </div>
            <div class="name_right"> {{ option.name }}</div>
        </template>

        <!-- Option Slot - what get shows on the drop down-->
        <template slot="option" slot-scope="props">
            <slot name="option" :option="props.option">
                <div class="name_left">
                    <div class="inner_div" :style="gravatarUrl(props.option)"></div>
                </div>
                <div class="name_right"> {{ props.option.name }}</div>
            </slot>
        </template>

    </multiselect>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import {commonErrorCallback} from '../../utility/Error';
  import UsersRequest from '../../api/UsersRequest';
  import debounce from 'lodash.debounce';

  export default {
    components: {
      Multiselect
    },

    props: {
      value: {
        required: false
      },
      showUnassigned:false,
      closeOnSelect: {
        required: false,
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        loading: false,
        users: []
      }
    },

    created() {
      // Trigger search without query
      // which will fetch initial dropdown
      // options
      this.search()
    },

    watch: {
      customers() {
        this.$nextTick(() => {
          if (this.$el.querySelector('.dropdown-toggle')) {
            $('.dropdown-toggle').dropdown()

            // Multi-select will close upon losing focus
            // re-open it right away
            $(this.$el).on('show.bs.dropdown', '.btn-group', () => {
              this.$nextTick(() => {
                this.$refs.dropdown.activate()
              })
            })
          }
        })
      }
    },

    methods: {
      name({id, name}) {
        return name
      },

      onSelect(value) {
        this.$emit('select', value)
      },

      onRemove(value) {
        this.$emit('remove', value)
      },

      search(query) {
        const successCb = (resp) => {
          this.loading = false
          this.users = resp.data.users.map(c => {
            return {
              id: c.id,
              name: c.firstName,
              gravatar: c.gravatar,
            }
          })

          if(this.showUnassigned){
            this.users.splice(0, 0, {
              id:-1,
              name:'Unassigned',
              gravatar: null,
            });
          }
        }

        const errorCb = (resp) => {
          this.loading = false
          commonErrorCallback(resp)
        }

        const parameters = {
          q: query,
          paginate: false
        }

        this.loading = true;

        (new UsersRequest).getUsers(parameters, successCb, errorCb)
      },
      gravatarUrl(user) {
        if (user && user.gravatar ) {
          return 'background-image:url(' + user.gravatar + ');';
        }
        return '';
      }
    },

    computed: {
      onSearchChange() {
        return debounce(this.search, 300)
      },

      value_: {
        get() {
          return this.value
        },
        set(newVal) {
          this.$emit('input', newVal)
        }
      }
    }
  }
</script>
<style scoped>
    .inner_div {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px inset;
        border-radius: 50%;
        background-position: center center;
    }

    .name_left {
        position: relative;
        float: left;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .name_right {
        margin-left: 25px;
    }
</style>