import http from '/@js/shared/http'

export default class ApiRequest {
  constructor(options) {
    this.options = Object.assign({}, this._getDefaultOptions(), options)

    this.http = http.create({
      baseURL: `/${this.options.routePrefix}`,
      timeout: this.options.timeout,
    })
  }

  logout() {
    window.location = '/auth/logout'
  }

  /**
   * @param {object} resp
   * @param {bool}   returnAsHtml
   * @return {array|string}
   */
  static getResponseErrors(resp, returnAsHtml = false) {
    const errors = []

    if (resp.data.error !== undefined) {
      errors.push(resp.data.error)
    }

    //TODO: Some api requests have a different error structure that this fails on.
    if (resp.data.errors !== undefined) {
      Object.entries(resp.data.errors).forEach(([key, errs]) => {
        errs.forEach((error) => {
          errors.push(error)
        })
      })
    }

    if (returnAsHtml) {
      let html = ''
      for (let i = 0; i < errors.length; i++) {
        html += `<br>${errors[i]}`
      }

      return html
    }

    return errors
  }

  /**
   * @return {object}
   */
  _getDefaultOptions() {
    return {
      routePrefix: 'api',
      timeout: 15000, // 15 secs
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  }

  /**
   * @return {string}
   */
  _stripLeadingSlash(route) {
    return route.indexOf('/') === 0 ? route.substring(1) : route
  }

  /**
   * @param {Error|object} error
   * @param {function} errorCB
   * @throws Error
   */
  _handleError(error, errorCb) {
    if (typeof error.response === 'object') {
      errorCb(error.response)
      return
    }

    throw new Error(error.message)
  }

  /**
   * Send a GET request
   *
   * @param {string}   route
   * @param {object}   queryParams
   * @param {function} successCb
   * @param {function} errorCb
   */
  get(route, queryParams, successCb, errorCb) {
    let params = {}
    if (typeof queryParams === 'object') {
      params = queryParams
    }
    this.http
      .get(this._stripLeadingSlash(route), { params })
      .then(successCb)
      .catch((err) => {
        this._handleError(err, errorCb)
      })
  }

  /**
   * Send a POST request
   *
   * @param {string}   route
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  post(route, payload, successCb, errorCb) {
    this.http
      .post(this._stripLeadingSlash(route), payload)
      .then(successCb)
      .catch((err) => {
        this._handleError(err, errorCb)
      })
  }

  /**
   * Send a PUT request
   *
   * @param {string}   route
   * @param {object}   payload
   * @param {function} successCb
   * @param {function} errorCb
   */
  put(route, payload, successCb, errorCb) {
    this.http
      .put(this._stripLeadingSlash(route), payload)
      .then(successCb)
      .catch((err) => {
        this._handleError(err, errorCb)
      })
  }

  /**
   * Send a DELETE request
   *
   * @param {string}   route
   * @param {function} successCb
   * @param {function} errorCb
   */
  delete(route, successCb, errorCb) {
    this.http
      .delete(this._stripLeadingSlash(route))
      .then(successCb)
      .catch((err) => {
        this._handleError(err, errorCb)
      })
  }
}
