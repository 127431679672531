
<multiselect
  ref="dropdown"
  v-model="value_"
  :loading="loading"
  :options="customers"
  :close-on-select="closeOnSelect"
  @select="onSelect"
  @remove="onRemove"
  @search-change="onSearchChange"
  track-by="id"
  :max-height="300"
  label="name"
  :custom-label="nameWithEmail"
  :show-labels="false"
  open-direction="bottom"
  placeholder="Search customers"
>
  <template slot="singleLabel" slot-scope="{ option }">
    <div class="text-sm" style="font-size: smaller">
      {{ option.name }} {{ option.email }}
      <span
        v-if="option.group"
        :class="'badge float-right badge-group ' + groupMembershipClass(option.group.alias)"
        >{{ option.group.alias }}</span
      >
    </div>
  </template>

  <!-- Option Slot - shown on the drop down-->
  <template slot="option" slot-scope="{ option }">
    <slot name="option" :option="option">
      <div>
        {{ option.name }}
        <span
          v-if="option.group && option.group.alias !== ''"
          class="badge float-right"
          :class="groupMembershipClass(option.group.alias)"
          >{{ option.group.alias }}</span
        >
      </div>
      <div>{{ `(${option.email})` || '(no email)' }}</div>
    </slot>
  </template>
</multiselect>
