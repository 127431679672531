import Vue from 'vue'

/* Icons */
import IconBase from '/@js/shared/icons/IconBase.vue'
import IconAdhesive from '/@js/shared/icons/icons/IconAdhesive.vue'
import IconDeleteLine from '/@js/shared/icons/icons/IconDeleteLine.vue'
import IconDownload from '/@js/shared/icons/icons/IconDownload.vue'
import IconDuplicate from '/@js/shared/icons/icons/IconDuplicate.vue'
import IconMove from '/@js/shared/icons/icons/IconMove.vue'
import IconPreview from '/@js/shared/icons/icons/IconPreview.vue'
import IconTime from '/@js/shared/icons/icons/IconTime.vue'

Vue.component('icon-base', IconBase)
Vue.component('icon-adhesive', IconAdhesive)
Vue.component('icon-delete-line', IconDeleteLine)
Vue.component('icon-download', IconDownload)
Vue.component('icon-duplicate', IconDuplicate)
Vue.component('icon-move', IconMove)
Vue.component('icon-preview', IconPreview)
Vue.component('icon-time', IconTime)

/* Shared */
import ButtonPreview from '/@js/shared/button-preview/index.vue'
import MaterialsSearchDropdown from '/@js/shared/materials-search-dropdown/index.vue'

Vue.component('button-preview', ButtonPreview)
Vue.component('materials-search-dropdown', MaterialsSearchDropdown)

/* Components */
import CustomerSearchDropdown from '/@js/components/customers/CustomerSearchDropdown.vue'
import UserSearchDropdown from '/@js/components/users/UserSearchDropdown.vue'
import NewCustomerModal from '/@js/components/customers/NewCustomerModal.vue'
import EditCustomerModal from '/@js/components/customers/EditCustomerModal.vue'

Vue.component('customer-search-dropdown', CustomerSearchDropdown)
Vue.component('user-search-dropdown', UserSearchDropdown)
Vue.component('new-customer-modal', NewCustomerModal)
Vue.component('edit-customer-modal', EditCustomerModal)
